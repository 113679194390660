import React, { useState } from 'react';
import { Trash2, ImageIcon, Video, Loader2, Plus, ChevronDown, ChevronUp } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const QuestionCard = ({ question, index, onChange, onDelete }) => {
  const [uploading, setUploading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);

  const addOption = () => {
    const updatedQuestion = {
      ...question,
      options: [...question.options, '']
    };
    onChange(updatedQuestion);
  };

  const updateOption = (optionIndex, value) => {
    const newOptions = [...question.options];
    newOptions[optionIndex] = value;
    onChange({
      ...question,
      options: newOptions
    });
  };

  const removeOption = (optionIndex) => {
    const newOptions = question.options.filter((_, idx) => idx !== optionIndex);
    const updatedQuestion = {
      ...question,
      options: newOptions,
      correctAnswer: question.correctAnswer === question.options[optionIndex] ? '' : question.correctAnswer
    };
    onChange(updatedQuestion);
  };

  const handleFileUpload = async (event, type) => {
    const file = event.target.files[0];
    if (!file) return;

    if (type === 'image' && !file.type.startsWith('image/')) {
      alert('Please select an image file');
      return;
    }
    if (type === 'video' && !file.type.startsWith('video/')) {
      alert('Please select a video file');
      return;
    }

    const maxSize = 10 * 1024 * 1024;
    if (file.size > maxSize) {
      alert('File size must be less than 10MB');
      return;
    }

    try {
      setUploading(true);
      const tempUrl = URL.createObjectURL(file);
      onChange({
        ...question,
        media: tempUrl,
        mediaFile: file,
        mediaType: type
      });
    } catch (error) {
      console.error('File handling error:', error);
      alert('Failed to process file');
    } finally {
      setUploading(false);
    }
  };

  const removeMedia = () => {
    if (question.media && question.media.startsWith('blob:')) {
      URL.revokeObjectURL(question.media);
    }
    onChange({
      ...question,
      media: null,
      mediaFile: null,
      mediaType: null
    });
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.3 }}
      className="border border-indigo-100 rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-300"
    >
      <div className="p-6 space-y-4">
        {/* Header */}
        <div className="flex justify-between items-center">
          <motion.h3
            className="text-xl font-semibold text-indigo-700"
            layoutId={`question-title-${index}`}
          >
            Question {index + 1}
          </motion.h3>
          <div className="flex items-center space-x-2">
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsExpanded(!isExpanded)}
              className="text-indigo-500 hover:text-indigo-700 transition-colors"
            >
              {isExpanded ? <ChevronUp className="h-5 w-5" /> : <ChevronDown className="h-5 w-5" />}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={onDelete}
              className="text-red-400 hover:text-red-600 transition-colors"
            >
              <Trash2 className="h-5 w-5" />
            </motion.button>
          </div>
        </div>

        <AnimatePresence>
          {isExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              {/* Question Text */}
              <motion.div layout className="mb-4">
                <textarea
                  value={question.text}
                  onChange={(e) => onChange({ ...question, text: e.target.value })}
                  className="w-full px-4 py-3 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                  placeholder="Enter your question"
                  rows={3}
                  required
                />
              </motion.div>

              {/* Question Type & Points */}
              <motion.div layout className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-sm font-medium text-indigo-600 mb-1">
                    Question Type
                  </label>
                  <select
                    value={question.type}
                    onChange={(e) => {
                      const type = e.target.value;
                      const updatedQuestion = {
                        ...question,
                        type,
                        options: type === 'multiple_choice' ? ['', ''] : 
                                type === 'true_false' ? ['True', 'False'] : [],
                        correctAnswer: ''
                      };
                      onChange(updatedQuestion);
                    }}
                    className="w-full px-4 py-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                  >
                    <option value="multiple_choice">Multiple Choice</option>
                    <option value="true_false">True/False</option>
                    <option value="short_answer">Short Answer</option>
                  </select>
                </div>

                <div>
                  <label className="block text-sm font-medium text-indigo-600 mb-1">
                    Points
                  </label>
                  <input
                    type="number"
                    value={question.points}
                    onChange={(e) => onChange({ ...question, points: parseInt(e.target.value) || 0 })}
                    className="w-full px-4 py-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                    min="1"
                    required
                  />
                </div>
              </motion.div>

              {/* Multiple Choice Options */}
              {question.type === 'multiple_choice' && (
                <motion.div layout className="space-y-3 mb-4">
                  <label className="block text-sm font-medium text-indigo-600 mb-1">
                    Options (Select the correct answer)
                  </label>
                  <AnimatePresence>
                    {question.options.map((option, optionIndex) => (
                      <motion.div
                        key={optionIndex}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.2 }}
                        className="flex items-center gap-3"
                      >
                        <input
                          type="radio"
                          name={`question-${question.id}-correct`}
                          checked={question.correctAnswer === option}
                          onChange={() => onChange({ ...question, correctAnswer: option })}
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                        />
                        <input
                          type="text"
                          value={option}
                          onChange={(e) => updateOption(optionIndex, e.target.value)}
                          className="flex-1 px-4 py-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                          placeholder={`Option ${optionIndex + 1}`}
                          required
                        />
                        {question.options.length > 2 && (
                          <motion.button
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            onClick={() => removeOption(optionIndex)}
                            className="text-red-400 hover:text-red-600 transition-colors"
                          >
                            <Trash2 className="h-4 w-4" />
                          </motion.button>
                        )}
                      </motion.div>
                    ))}
                  </AnimatePresence>
                  
                  <motion.button
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={addOption}
                    className="w-full py-2 px-4 border border-indigo-200 rounded-lg text-indigo-600 hover:bg-indigo-50 transition-all duration-200 flex items-center justify-center"
                  >
                    <Plus className="h-4 w-4 mr-2" />
                    Add Option
                  </motion.button>
                </motion.div>
              )}

              {/* True/False Options */}
              {question.type === 'true_false' && (
                <motion.div layout className="space-y-3 mb-4">
                  <label className="block text-sm font-medium text-indigo-600 mb-1">
                    Select the correct answer
                  </label>
                  <div className="flex gap-4">
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name={`question-${question.id}-true-false`}
                        checked={question.correctAnswer === 'True'}
                        onChange={() => onChange({ ...question, correctAnswer: 'True' })}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                      />
                      <span>True</span>
                    </label>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        name={`question-${question.id}-true-false`}
                        checked={question.correctAnswer === 'False'}
                        onChange={() => onChange({ ...question, correctAnswer: 'False' })}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500"
                      />
                      <span>False</span>
                    </label>
                  </div>
                </motion.div>
              )}

              {/* Short Answer Correct Answer */}
              {question.type === 'short_answer' && (
                <motion.div layout className="mb-4">
                  <label className="block text-sm font-medium text-indigo-600 mb-1">
                    Correct Answer
                  </label>
                  <input
                    type="text"
                    value={question.correctAnswer}
                    onChange={(e) => onChange({ ...question, correctAnswer: e.target.value })}
                    className="w-full px-4 py-2 border border-indigo-200 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                    placeholder="Enter the correct answer"
                    required
                  />
                </motion.div>
              )}

              {/* Media Upload Section */}
              <motion.div layout className="space-y-4">
                <label className="block text-sm font-medium text-indigo-600 mb-2">
                  Media (Optional)
                </label>
                <div className="flex gap-3">
                  <motion.label
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center gap-2 px-4 py-2 border border-indigo-200 rounded-lg ${uploading ? 'bg-indigo-100 cursor-not-allowed' : 'text-indigo-600 hover:bg-indigo-50 cursor-pointer'} transition-all duration-200`}
                  >
                    <ImageIcon className="h-5 w-5" />
                    Add Image
                    <input
                      type="file"
                      accept="image/*"
                      className="hidden"
                      onChange={(e) => handleFileUpload(e, 'image')}
                      disabled={uploading}
                    />
                  </motion.label>
                  <motion.label
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className={`flex items-center gap-2 px-4 py-2 border border-indigo-200 rounded-lg ${uploading ? 'bg-indigo-100 cursor-not-allowed' : 'text-indigo-600 hover:bg-indigo-50 cursor-pointer'} transition-all duration-200`}
                  >
                    <Video className="h-5 w-5" />
                    Add Video
                    <input
                      type="file"
                      accept="video/*"
                      className="hidden"
                      onChange={(e) => handleFileUpload(e, 'video')}
                      disabled={uploading}
                    />
                  </motion.label>
                </div>

                {uploading && (
                  <div className="flex items-center gap-2 text-sm text-indigo-500">
                    <Loader2 className="h-4 w-4 animate-spin" />
                    <span>Processing media...</span>
                  </div>
                )}

                {question.media && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    transition={{ duration: 0.3 }}
                    className="relative inline-block"
                  >
                    {(question.mediaType === 'image' || question.media.match(/\.(jpg|jpeg|png|gif)$/i)) ? (
                      <img
                        src={question.media}
                        alt="Question media"
                        className="max-w-xs rounded-lg shadow-md"
                      />
                    ) : (question.mediaType === 'video' || question.media.match(/\.(mp4|webm|ogg)$/i)) ? (
                      <video
                        src={question.media}
                        controls
                        className="max-w-xs rounded-lg shadow-md"
                      />
                    ) : null}
                    <motion.button
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.9 }}
                      onClick={removeMedia}
                      className="absolute top-2 right-2 p-1 bg-red-500 text-white rounded-full hover:bg-red-600 transition-colors"
                      title="Remove media"
                    >
                      <Trash2 className="h-4 w-4" />
                    </motion.button>
                  </motion.div>
                )}
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default QuestionCard;

