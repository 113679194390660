// client/src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="dev-akdhhoq367cdj4en.us.auth0.com"    // Replace with your Auth0 domain
      clientId="pZETL93DlR1oOWETc0xwsmIYW1T8as37"         // Replace with your Auth0 client ID
      redirectUri={window.location.origin}
    >
      <App />
    </Auth0Provider>
  </React.StrictMode>
);

reportWebVitals();