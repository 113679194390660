// src/pages/EditQuiz.jsx
import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import Navbar from '../components/layout/Navbar';
import Sidebar from '../components/layout/Sidebar';


const EditQuiz = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState(null);

  useEffect(() => {
    // Fetch quiz data from API
    // This is mock data - replace with actual API call
    setQuiz({
      id,
      title: "Sample Quiz",
      description: "Sample Description",
      timeLimit: 30,
      questions: []
    });
  }, [id]);

  if (!quiz) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex">
        <Sidebar />
        <main className="flex-1 p-8 ml-64 mt-16">
          <div className="max-w-4xl mx-auto">
            <button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 text-gray-600 mb-6 hover:text-gray-900"
            >
              <ArrowLeft className="h-5 w-5" />
              Back to Quiz
            </button>

            <div className="bg-white rounded-xl shadow-sm p-8">
              <h1 className="text-2xl font-bold text-gray-900 mb-6">Edit Quiz</h1>
              {/* Add quiz editing form similar to CreateQuiz */}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};
export default EditQuiz;