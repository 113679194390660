import React, { useState } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';

const AppLayout = ({ children }) => {
  const [openSide,SetOpenSide]=useState(false)
  return (
    <div className="min-h-screen bg-gray-100 flex flex-col">
      {/* Navbar */}
      <Navbar sideOpen={openSide} setSideOpen={SetOpenSide} />

      {/* Main content area with Sidebar */}
      <div className="flex flex-1">
        {/* Sidebar */}
        <Sidebar open={openSide} setOpen={SetOpenSide}/>

        {/* Main content area */}
        <main className="flex-1 md:ml-64 mt-2 p-2 transition-all duration-300 ease-in-out">
          <div className="max-w-7xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

export default AppLayout;