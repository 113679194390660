import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axious";

const Auth = () => {
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(true);
  const [formData, setFormData] = useState({
    user_name: "",
    email: "",
    contact: "",
    password: "",
  });
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Axios instance


  // Input Change Handler
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value.trim(),
    });
  };

  // Form Validation
  const validateForm = () => {
    if (!formData.user_name || !formData.password || (!isLogin && (!formData.email || !formData.contact))) {
      setError("All fields are required.");
      return false;
    }

    if (!isLogin) {
      if (!/\S+@\S+\.\S+/.test(formData.email)) {
        setError("Please enter a valid email address.");
        return false;
      }
      if (!/^\d{10}$/.test(formData.contact)) {
        setError("Contact must be a 10-digit number.");
        return false;
      }
    }

    if (formData.password.length < 6) {
      setError("Password must be at least 6 characters long.");
      return false;
    }

    return true;
  };

  // Form Submit Handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
  
    if (!validateForm()) return;

    setLoading(true);

    try {
      if (isLogin) {
        // Login Request
        const response = await api.post("/auth/login", {
          user_name: formData.user_name,
          password: formData.password,
          
        });

        if (response.data.success) {
          localStorage.setItem("user", JSON.stringify(response.data.user));
          navigate("/dashboard");
        } else {
          setError(response.data.message || "Login failed. Please try again.");
        }
      } else {
        // Signup Request
        const response = await api.post("/auth/signup", {
          user_name: formData.user_name,
          email: formData.email,
          contact: formData.contact,
          password: formData.password,
          role: "user", // Default role
        });

        if (response.data.success) {
          setIsLogin(true); // Switch to login mode after successful signup
          setFormData({
            user_name: "",
            email: "",
            contact: "",
            password: "",
          });
          alert("Signup successful! Please login.");
        } else {
          setError(response.data.message || "Signup failed. Please try again.");
        }
      }
    } catch (error) {
      setError(error.response?.data?.message || "An error occurred. Please try again.");
      console.error("Auth error:", error);
    } finally {
      setLoading(false);
    }
  };

  // Switch Mode (Login <-> Signup)
  const toggleMode = () => {
    setIsLogin(!isLogin);
    setError("");
    setFormData({
      user_name: "",
      email: "",
      contact: "",
      password: "",
    });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-lg shadow">
        <h2 className="text-3xl font-bold text-center">{isLogin ? "Login" : "Sign Up"}</h2>

        {error && (
          <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded">
            {error}
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700">User Name</label>
            <input
              type="text"
              name="user_name"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              value={formData.user_name}
              onChange={handleInputChange}
            />
          </div>

          {!isLogin && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">Contact</label>
                <input
                  type="tel"
                  name="contact"
                  required
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
                  value={formData.contact}
                  onChange={handleInputChange}
                />
              </div>
            </>
          )}

          <div>
            <label className="block text-sm font-medium text-gray-700">Password</label>
            <input
              type="password"
              name="password"
              required
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md"
              value={formData.password}
              onChange={handleInputChange}
            />
          </div>

          <button
            type="submit"
            disabled={loading}
            className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
              loading ? "bg-gray-500 cursor-not-allowed" : "bg-blue-600 hover:bg-blue-700"
            }`}
          >
            {loading ? "Processing..." : isLogin ? "Login" : "Sign Up"}
          </button>
        </form>

        <div className="text-center mt-4">
          <button onClick={toggleMode} className="text-blue-600 hover:text-blue-800">
            {isLogin ? "Need an account? Sign Up" : "Already have an account? Login"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Auth;
