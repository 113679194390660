// src/pages/Results.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Navbar from '../components/layout/Navbar';
import Sidebar from '../components/layout/Sidebar';

import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';

const Results = () => {
  const { id } = useParams();
  const [results, setResults] = useState(null);
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    // Fetch results data from API
    // This is mock data - replace with actual API call
    setResults({
      participants: 45,
      averageScore: 78,
      questions: [
        { id: 1, correctAnswers: 35, wrongAnswers: 10 },
        { id: 2, correctAnswers: 40, wrongAnswers: 5 },
      ]
    });

    // Transform questions data for the chart
    const transformedData = results?.questions.map(q => ({
      question: `Question ${q.id}`,
      correct: q.correctAnswers,
      wrong: q.wrongAnswers
    })) || [];

    setChartData(transformedData);
  }, [id]);

  if (!results) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Navbar />
      <div className="flex">
        <Sidebar />
        <main className="flex-1 p-8 ml-64 mt-16">
          <div className="max-w-7xl mx-auto">
            <div className="bg-white rounded-xl shadow-sm p-8">
              <h1 className="text-2xl font-bold text-gray-900 mb-6">Quiz Results</h1>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
                <div className="bg-blue-50 p-6 rounded-xl">
                  <h3 className="text-sm font-medium text-blue-600">Total Participants</h3>
                  <p className="text-2xl font-bold mt-2">{results.participants}</p>
                </div>
                <div className="bg-green-50 p-6 rounded-xl">
                  <h3 className="text-sm font-medium text-green-600">Average Score</h3>
                  <p className="text-2xl font-bold mt-2">{results.averageScore}%</p>
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Question Analysis</h2>
                <div className="h-96">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={chartData}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="question" />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="correct" fill="#4CAF50" name="Correct Answers" />
                      <Bar dataKey="wrong" fill="#F44336" name="Wrong Answers" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              </div>

              <div className="mt-8">
                <h2 className="text-xl font-semibold mb-4">Detailed Statistics</h2>
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead>
                      <tr>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Question
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Correct Answers
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Wrong Answers
                        </th>
                        <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          Success Rate
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {results.questions.map((question) => {
                        const total = question.correctAnswers + question.wrongAnswers;
                        const successRate = ((question.correctAnswers / total) * 100).toFixed(1);
                        
                        return (
                          <tr key={question.id}>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              Question {question.id}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-green-600 font-medium">
                              {question.correctAnswers}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-red-600 font-medium">
                              {question.wrongAnswers}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                              {successRate}%
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Results;