// import React from 'react';
// import { NavLink, useNavigate } from 'react-router-dom';
// import { LayoutDashboard, PlusCircle, BarChart2, Settings, Users, HelpCircle, LogOut } from 'lucide-react';

// const Sidebar = () => {
//   const navigate = useNavigate();

//   const handleLogout = () => {
//     // Clear user session
//     localStorage.removeItem('user');
//     // Redirect to login page
//     navigate('/login');
//   };

//   const navItems = [
//     { icon: <LayoutDashboard size={20} />, text: 'Dashboard', path: '/dashboard' },
//     { icon: <PlusCircle size={20} />, text: 'Create Quiz', path: '/createquiz' },
//     { icon: <BarChart2 size={20} />, text: 'Analytics', path: '/analytics' },
//     { icon: <Users size={20} />, text: 'Participants', path: '/participants' },
//     { icon: <Settings size={20} />, text: 'Settings', path: '/settings' },
//   ];

//   return (
//     <div className="fixed inset-y-0 left-0 w-64 bg-gradient-to-b from-indigo-600 to-purple-700 pt-16 pb-4 flex flex-col transition-all duration-300 ease-in-out shadow-xl">
//       <nav className="mt-6 flex-1 px-4 space-y-2">
//         {navItems.map((item) => (
//           <NavLink
//             key={item.path}
//             to={item.path}
//             className={({ isActive }) =>
//               `flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
//                 isActive
//                   ? 'bg-white text-indigo-600 shadow-md transform scale-105'
//                   : 'text-white hover:bg-indigo-500 hover:shadow-md'
//               }`
//             }
//           >
//             <span className="text-xl">{item.icon}</span>
//             <span className="font-semibold">{item.text}</span>
//           </NavLink>
//         ))}
//       </nav>

//       <div className="px-4 space-y-2 mt-6">
//         <button className="flex items-center gap-3 px-4 py-3 text-sm font-medium text-white hover:bg-indigo-500 rounded-lg w-full transition-all duration-200 hover:shadow-md">
//           <HelpCircle size={20} />
//           <span className="font-semibold">Help & Support</span>
//         </button>
//         <button
//           onClick={handleLogout}
//           className="flex items-center gap-3 px-4 py-3 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-lg w-full transition-all duration-200 hover:shadow-md"
//         >
//           <LogOut size={20} />
//           <span className="font-semibold">Logout</span>
//         </button>
//       </div>
//     </div>
//   );
// };

// export default Sidebar;

import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { 
  LayoutDashboard, 
  PlusCircle, 
  BarChart2, 
  Settings, 
  Users, 
  HelpCircle, 
  LogOut, 
  Menu, 
  X ,
  ChevronRightCircle,
  LucideChevronRight
} from 'lucide-react';

const Sidebar = ({open=false,setOpen}) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(open);

  const handleLogout = () => {
    localStorage.removeItem('user');
    navigate('/login');
  };

  const navItems = [
    { icon: <LayoutDashboard size={20} />, text: 'Dashboard', path: '/dashboard' },
    { icon: <PlusCircle size={20} />, text: 'Create Quiz', path: '/createquiz' },
    { icon: <BarChart2 size={20} />, text: 'Analytics', path: '/analytics' },
    { icon: <Users size={20} />, text: 'Participants', path: '/participants' },
    { icon: <Settings size={20} />, text: 'Settings', path: '/settings' },
  ];

  const toggleSidebar = () => {
    setOpen(!open);
  };

  return (
    <>
      {/* Sidebar with Toggle Button */}
      <div 
        className={`
          fixed inset-y-0 left-0 w-64 bg-gradient-to-b from-indigo-600 to-purple-700 
          pt-16 pb-4 flex flex-col transition-all duration-300 ease-in-out shadow-xl
          ${open ? 'translate-x-0' : '-translate-x-full'}
          md:translate-x-0 md:block
          z-[99]
        `}
      >
  
        {/* Close Button - Visible when sidebar is open */}
        {open && (
          <button 
            onClick={toggleSidebar}
            className="absolute top-4 right-4 text-white md:hidden"
          >
            <X size={24} />
          </button>
        )}

        <nav className="mt-6 flex-1 px-4 space-y-2 ">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              onClick={() => setOpen(false)} // Close sidebar on item click for mobile
              className={({ isActive }) =>
                `flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                  isActive
                    ? 'bg-white text-indigo-600 shadow-md transform scale-105'
                    : 'text-white hover:bg-indigo-500 hover:shadow-md'
                }`
              }
            >
              <span className="text-xl">{item.icon}</span>
              <span className="font-semibold">{item.text}</span>
            </NavLink>
          ))}
        </nav>

        {/* <nav className="mt-6 flex-1 px-4 space-y-2 md:hidden ">
          {navItems.map((item) => (
            <NavLink
              key={item.path}
              to={item.path}
              onClick={() => setOpen(false)} // Close sidebar on item click for mobile
              className={({ isActive }) =>
                `flex items-center gap-3 px-4 py-3 text-sm font-medium rounded-lg transition-all duration-200 ${
                  isActive
                    ? 'bg-white text-indigo-600 shadow-md transform scale-105'
                    : 'text-white hover:bg-indigo-500 hover:shadow-md'
                }`
              }
            >
              <span className="text-xl">{item.icon}</span>
              <span className="font-semibold">{item.text}</span>
            </NavLink>
          ))}
        </nav> */}
        <div className="px-4 space-y-2 mt-6">
          <button className="flex items-center gap-3 px-4 py-3 text-sm font-medium text-white hover:bg-indigo-500 rounded-lg w-full transition-all duration-200 hover:shadow-md">
            <HelpCircle size={20} />
            <span className="font-semibold">Help & Support</span>
          </button>
          <button
            onClick={handleLogout}
            className="flex items-center gap-3 px-4 py-3 text-sm font-medium text-white bg-red-500 hover:bg-red-600 rounded-lg w-full transition-all duration-200 hover:shadow-md"
          >
            <LogOut size={20} />
            <span className="font-semibold">Logout</span>
          </button>
        </div>
      </div>

      {/* Overlay for mobile when sidebar is open */}
      {open && (
        <div 
          onClick={() => setIsOpen(false)}
          className="fixed inset-0   backdrop-blur-md z-30 md:hidden"
        />
      )}
    </>
  );
};

export default Sidebar;