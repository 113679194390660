import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus, Users, Clock, Trophy, FileText, Edit, ChevronRight, Bell, Search, Settings, HelpCircle, BarChart2, PieChart } from 'lucide-react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  ResponsiveContainer, PieChart as RechartsPieChart, Pie, Cell, Area, AreaChart
} from 'recharts';
import AppLayout from '../components/layout/AppLayout';
import api from '../config/axious';

const StatCard = ({ title, value, icon, trend, color }) => {
  return (
    <div className={`bg-white rounded-lg shadow-md hover:shadow-lg transition-all duration-300 transform hover:-translate-y-1 p-4 sm:p-6 border-l-4 ${color}`}>
      <div className="flex justify-between items-center">
        <div>
          <p className="text-sm font-medium text-gray-500 mb-2">{title}</p>
          <p className={`text-2xl sm:text-3xl font-bold ${color.replace('border-', 'text-')}`}>{value}</p>
          {trend && (
            <p className={`text-xs mt-2 flex items-center ${trend.includes('+') ? 'text-green-600' : 'text-red-600'}`}>
              {trend.includes('+') ? (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z" clipRule="evenodd" />
                </svg>
              ) : (
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-1" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M14.707 10.293a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 111.414-1.414L9 12.586V5a1 1 0 012 0v7.586l2.293-2.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              )}
              {trend}
            </p>
          )}
        </div>
        <div className={`${color.replace('border-', 'text-')} bg-opacity-20 p-3 rounded-full`}>
          {icon}
        </div>
      </div>
    </div>
  );
};

const QuizCard = ({ quiz }) => {
  const navigate = useNavigate();

  const handleEdit = () => {
    navigate(`/edit-quiz/${quiz.id}`);
  };

  return (
    <div className="bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-2 p-4 sm:p-6 border border-gray-200">
      <div className="flex justify-between items-start mb-4">
        <h3 className="text-lg sm:text-xl font-bold text-gray-900 truncate pr-4">{quiz.title}</h3>
        <div className="flex space-x-2">
          <button onClick={handleEdit} className="text-gray-500 hover:text-blue-600 transition-colors p-1.5 rounded-full hover:bg-blue-50" title="Edit Quiz">
            <Edit size={18} />
          </button>
          <button onClick={() => navigate(`/quiz_preview/${quiz.id}`)} className="text-gray-500 hover:text-green-600 transition-colors p-1.5 rounded-full hover:bg-green-50" title="View Quiz">
            <FileText size={18} />
          </button>
        </div>
      </div>

      <p className="text-gray-600 mb-4 line-clamp-2 text-sm">{quiz.description}</p>

      <div className="grid grid-cols-3 gap-2 sm:gap-4 text-xs sm:text-sm mb-4 border-t border-b py-3 border-gray-100">
        <div className="flex items-center text-gray-500">
          <Clock className="mr-1 sm:mr-2 text-blue-500" size={14} />
          <span>{quiz.timeLimit} min</span>
        </div>
        <div className="flex items-center text-gray-500">
          <Users className="mr-1 sm:mr-2 text-green-500" size={14} />
          <span>{quiz.participants} users</span>
        </div>
        <div className="flex items-center text-gray-500">
          <Trophy className="mr-1 sm:mr-2 text-yellow-500" size={14} />
          <span>{quiz.avgScore}% avg</span>
        </div>
      </div>

      <div className="text-xs text-gray-400 flex justify-between items-center">
        <span>Created: {new Date(quiz.createdAt).toLocaleDateString()}</span>
        <button onClick={() => navigate(`/quiz_details/${quiz.id}`)} className="flex items-center text-blue-500 hover:text-blue-600 transition-colors">
          View Details
          <ChevronRight className="ml-1" size={14} />
        </button>
      </div>
    </div>
  );
};

const EnhancedPerformanceChart = ({ quizzes }) => {
  const chartData = quizzes.map(quiz => ({
    name: quiz.title.length > 10 ? quiz.title.substring(0, 10) + '...' : quiz.title,
    'Avg Score': quiz.avgScore,
    'Participants': quiz.participants
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <AreaChart data={chartData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="colorScore" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#3B82F6" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#3B82F6" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorParticipants" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#10B981" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#10B981" stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray="3 3" stroke="#E5E7EB" />
        <XAxis dataKey="name" stroke="#6B7280" angle={-45} textAnchor="end" height={60} tick={{fontSize: 12}} />
        <YAxis stroke="#6B7280" tick={{fontSize: 12}} />
        <Tooltip
          contentStyle={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            border: 'none',
            fontSize: '12px'
          }}
        />
        <Legend wrapperStyle={{fontSize: '12px'}} />
        <Area type="monotone" dataKey="Avg Score" stroke="#3B82F6" fillOpacity={1} fill="url(#colorScore)" />
        <Area type="monotone" dataKey="Participants" stroke="#10B981" fillOpacity={1} fill="url(#colorParticipants)" />
      </AreaChart>
    </ResponsiveContainer>
  );
};

const EnhancedQuizDistributionChart = ({ quizzes }) => {
  const COLORS = ['#3B82F6', '#10B981', '#F59E0B', '#EF4444', '#8B5CF6', '#EC4899', '#6366F1', '#14B8A6', '#F97316', '#8B5CF6'];

  const chartData = quizzes.map(quiz => ({
    name: quiz.title.length > 15 ? quiz.title.substring(0, 15) + '...' : quiz.title,
    value: quiz.participants
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <RechartsPieChart>
        <Pie
          data={chartData}
          cx="50%"
          cy="50%"
          innerRadius={60}
          outerRadius={90}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
          label={({ name, percent }) => `${name} (${(percent * 100).toFixed(0)}%)`}
          labelLine={{ stroke: '#555', strokeWidth: 1, strokeDasharray: '3 3' }}
        >
          {chartData.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              className="hover:opacity-80 transition-opacity"
            />
          ))}
        </Pie>
        <Tooltip
          contentStyle={{
            backgroundColor: '#ffffff',
            borderRadius: '8px',
            boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
            border: 'none',
            fontSize: '12px'
          }}
        />
        <Legend layout="vertical" align="right" verticalAlign="middle" wrapperStyle={{fontSize: '12px'}} />
      </RechartsPieChart>
    </ResponsiveContainer>
  );
};

const Dashboard = () => {
  const navigate = useNavigate();
  const [quizzes, setQuizzes] = useState([]);
  const [stats, setStats] = useState({
    totalQuizzes: 0,
    totalParticipants: 0,
    averageScore: 0,
    completionRate: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('performance');
  const [showAllQuizzes, setShowAllQuizzes] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, []);

  const fetchDashboardData = async () => {
    try {
      setLoading(true);

      const user = JSON.parse(localStorage.getItem('user'));
      if (!user || !user.id) {
        throw new Error('User not authenticated');
      }
      const userId = user.id;

      const quizzesResponse = await api.get(`/api/quizzes?userId=${userId}`);
      const quizzesData = await quizzesResponse.data;

      const statsResponse = await api.get(`/api/quizzes/stats?userId=${userId}`);
      const statsData = await statsResponse.data;

      if (quizzesData.success && statsData.success) {
        setQuizzes(quizzesData.quizzes);
        setStats(statsData.stats);
      } else {
        throw new Error('Failed to fetch dashboard data');
      }
    } catch (err) {
      console.error('Error fetching dashboard data:', err);
      setError(err.message || 'Failed to load dashboard data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="text-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500 mx-auto mb-4"></div>
          <p className="text-gray-600 text-lg">Loading Dashboard...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center px-4">
        <div className="bg-white p-6 rounded-xl shadow-lg text-center max-w-md w-full">
          <div className="text-red-500 mb-4">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          </div>
          <h2 className="text-xl font-bold text-gray-800 mb-4">Oops! Something went wrong</h2>
          <p className="text-gray-600 mb-6">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors text-sm"
          >
            Reload Page
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto space-y-6 px-4 py-8">
      <div className="flex flex-wrap flex-col md:flex-row md:space-y-2 justify-between items-center bg-white p-4 md:p-6 rounded-lg shadow-md">
        <h1 className="text-2xl md:text-3xl md:w-full font-extrabold md:text-center text-gray-900 bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-purple-600 mb-4 md:mb-0">
          Welcome to Your Dashboard
        </h1>
        <div className="flex flex-col md:flex-row md:w-full  items-center space-y-4 md:space-y-0 md:space-x-4">
          <div className="relative w-full ">
            <input
              type="text"
              placeholder="Search..."
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 transition"
            />
            <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
          </div>
          <button
            onClick={() => navigate('/createquiz')}
            className="w-full md:w-auto bg-blue-500 text-nowrap text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center"
          >
            <Plus className="mr-2" size={20} />
            Create Quiz
          </button>
          <Bell className="text-gray-500 hover:text-blue-500 transition-colors cursor-pointer" size={24} />
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <StatCard 
          title="Total Quizzes" 
          value={stats.totalQuizzes} 
          icon={<PieChart size={24} />} 
          color="border-blue-500" 
          trend="+12.5%" 
        />
        <StatCard 
          title="Total Participants" 
          value={stats.totalParticipants} 
          icon={<Users size={24} />} 
          color="border-green-500" 
          trend="+8.3%" 
        />
        <StatCard 
          title="Average Score" 
          value={`${stats.averageScore}%`} 
          icon={<Trophy size={24} />} 
          color="border-yellow-500" 
          trend="+5.2%" 
        />
        <StatCard 
          title="Completion Rate" 
          value={`${stats.completionRate}%`} 
          icon={<BarChart2 size={24} />} 
          color="border-purple-500" 
          trend="-2.1%" 
        />
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4 md:mb-0">Quiz Performance</h2>
          <div className="flex space-x-2 bg-gray-100 rounded-lg p-1">
            <button 
              onClick={() => setActiveTab('performance')}
              className={`px-3 py-1 rounded-md transition-colors text-sm ${
                activeTab === 'performance' 
                  ? 'bg-blue-500 text-white' 
                  : 'text-gray-600 hover:bg-gray-200'
              }`}
            >
              Performance
            </button>
            <button 
              onClick={() => setActiveTab('distribution')}
              className={`px-3 py-1 rounded-md transition-colors text-sm ${
                activeTab === 'distribution' 
                  ? 'bg-blue-500 text-white' 
                  : 'text-gray-600 hover:bg-gray-200'
              }`}
            >
              Distribution
            </button>
          </div>
        </div>
        
        {activeTab === 'performance' ? (
          <EnhancedPerformanceChart quizzes={quizzes} />
        ) : (
          <EnhancedQuizDistributionChart quizzes={quizzes} />
        )}
      </div>

      <div className="bg-white rounded-lg shadow-md p-4 md:p-6">
        <div className="flex flex-col md:flex-row justify-between items-center mb-6">
          <h2 className="text-xl md:text-2xl font-bold text-gray-900 mb-4 md:mb-0">Your Quizzes</h2>
          <div className="flex items-center space-x-4">
            <button 
              onClick={() => setShowAllQuizzes(!showAllQuizzes)}
              className="text-blue-500 hover:text-blue-600 transition-colors text-sm"
            >
              {showAllQuizzes ? 'Show Less' : 'Show All'}
            </button>
            <Settings className="text-gray-500 hover:text-gray-700 cursor-pointer" size={20} />
            <HelpCircle className="text-gray-500 hover:text-gray-700 cursor-pointer" size={20} />
          </div>
        </div>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {(showAllQuizzes ? quizzes : quizzes.slice(0, 3)).map(quiz => (
            <QuizCard key={quiz.id} quiz={quiz} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

