import React, { useState } from 'react';
import { QRCodeSVG } from 'qrcode.react';
import { Copy, Share2 } from 'lucide-react';
import { toast } from 'sonner'; // Assuming you're using sonner for notifications

const QuizShareModal = ({ shareLink, onClose }) => {
  const [copied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareLink);
    setCopied(true);
    toast.success('Quiz link copied to clipboard!');
    
    // Reset copied state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  };

  const handleShareSocial = () => {
    if (navigator.share) {
      navigator.share({
        title: 'Take this Quiz!',
        text: 'Check out this exciting quiz',
        url: shareLink
      }).catch(console.error);
    } else {
      toast.error('Web Share API not supported');
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl p-8 max-w-md w-full shadow-2xl relative">
        <button 
          onClick={onClose} 
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
        >
          ✕
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center">Share Your Quiz</h2>
        
        <div className="flex justify-center mb-6">
          <QRCodeSVG 
            value={shareLink} 
            size={256} 
            level={'H'} 
            includeMargin={true} 
            className="border-4 border-gray-200 rounded-lg"
          />
        </div>

        <div className="flex items-center border rounded-lg overflow-hidden mb-4">
          <input 
            type="text" 
            value={shareLink} 
            readOnly 
            className="flex-1 px-4 py-2 text-gray-700 truncate"
          />
          <button 
            onClick={handleCopyLink}
            className={`px-4 py-2 ${copied ? 'bg-green-500 text-white' : 'bg-gray-100 text-gray-700'} hover:bg-gray-200`}
          >
            <Copy className="h-5 w-5" />
          </button>
        </div>

        <div className="flex space-x-4">
          <button 
            onClick={handleShareSocial}
            className="flex-1 flex items-center justify-center gap-2 bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-700"
          >
            <Share2 className="h-5 w-5" />
            Share
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuizShareModal;