import React, { useState, useEffect } from 'react';
import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  PieChart, 
  Pie, 
  Cell 
} from 'recharts';
import { 
  Trophy, 
  Users, 
  BookOpen, 
  CheckCircle 
} from 'lucide-react';
import { EmailModal } from '../components/common/EmailModal';

const QuizAnalyticsDashboard = () => {
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const response = await fetch('/api/quizzes/stats');
        const data = await response.json();
        
        if (data.success) {
          setStats(data.stats);
        } else {
          setError('Failed to fetch analytics');
        }
      } catch (err) {
        setError('Network error occurred');
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative" role="alert">
        {error}
      </div>
    );
  }

  // Sample data for charts (supplementing existing stats)
  const participationData = [
    { name: 'Total Quizzes', value: stats.totalQuizzes },
    { name: 'Total Participants', value: stats.totalParticipants }
  ];

  const COLORS = ['#0088FE', '#00C49F'];

  const performanceData = [
    { name: 'Average Score', value: stats.averageScore },
    { name: 'Completion Rate', value: stats.completionRate }
  ];

  return (
    <div className="container mx-auto p-6 bg-gray-50 min-h-screen">
      <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Quiz Analytics Dashboard</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Key Metrics Cards */}
        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col">
          <div className="flex items-center mb-4">
            <Trophy className="text-blue-500 mr-4" size={40} />
            <h2 className="text-xl font-semibold text-gray-700">Total Quizzes</h2>
          </div>
          <p className="text-4xl font-bold text-blue-600">{stats.totalQuizzes}</p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col">
          <div className="flex items-center mb-4">
            <Users className="text-green-500 mr-4" size={40} />
            <h2 className="text-xl font-semibold text-gray-700">Total Participants</h2>
          </div>
          <p className="text-4xl font-bold text-green-600">{stats.totalParticipants}</p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col">
          <div className="flex items-center mb-4">
            <BookOpen className="text-purple-500 mr-4" size={40} />
            <h2 className="text-xl font-semibold text-gray-700">Average Score</h2>
          </div>
          <p className="text-4xl font-bold text-purple-600">{stats.averageScore}%</p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 flex flex-col">
          <div className="flex items-center mb-4">
            <CheckCircle className="text-orange-500 mr-4" size={40} />
            <h2 className="text-xl font-semibold text-gray-700">Completion Rate</h2>
          </div>
          <p className="text-4xl font-bold text-orange-600">{stats.completionRate}%</p>
        </div>

        {/* Charts Section */}
        <div className="bg-white shadow-lg rounded-lg p-6 col-span-full">
          <h2 className="text-2xl font-semibold mb-6 text-center">Participation Overview</h2>
          <div className="flex justify-center items-center">
            <PieChart width={400} height={300}>
              <Pie
                data={participationData}
                cx={200}
                cy={150}
                labelLine={false}
                outerRadius={120}
                fill="#8884d8"
                dataKey="value"
              >
                {participationData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6 col-span-full">
          <h2 className="text-2xl font-semibold mb-6 text-center">Performance Metrics</h2>
          <div className="flex justify-center items-center">
            <BarChart width={600} height={300} data={performanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8" />
            </BarChart>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizAnalyticsDashboard;