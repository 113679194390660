// import React, { useState } from 'react';

// export function EmailModal({ onVerified }) {
//   const [email, setPemail] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(true);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);

//     try {
//       const response = await fetch('http://localhost:4000/api/participants/verify', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       const data = await response.json();

//       if (!response.ok) {
//         throw new Error(data.message || 'Verification failed');
//       }

//       onVerified(email);
//       setIsOpen(false);
//     } catch (error) {
//       alert('Failed to verify email');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 backdrop-blur-xl">
//       <div className="bg-[royalblue]/60 rounded-lg p-6 w-full max-w-md ">
//         <h2 className="text-2xl font-bold mb-4 text-white/80">Enter your email to start the quiz</h2>
//         <form onSubmit={handleSubmit} className="space-y-4">
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setPemail(e.target.value)}
//             required
//             className="w-full p-2 border border-gray-300 rounded"
//           />
//           <button
//             type="submit"
//             className={`w-full p-2 text-white rounded font-bold ${
//               isLoading ? 'bg-gray-400' : 'bg-green-600 hover:bg-green-400'
//             }`}
//             disabled={isLoading}
//           >
//             {isLoading ? 'Verifying...' : 'Continue'}
//           </button>
//         </form>
//       </div>
//     </div>
//   );
// }

// import React, { useEffect, useState } from 'react';
// import api from '../../config/axious';
// export function EmailModal({ token,setemail,setname,shouldplay}) {
//   const [email,setPemail ] = useState('');
//   const [name, setpname] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isOpen, setIsOpen] = useState(true);
//   const [message, setMessage] = useState(null);
//   const [canPlay,setcanPlay]=useState(false);


// useEffect(()=>{
//     setemail(email)
//     setname(name)
// },[email,name])

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     setIsLoading(true);
//     setMessage(null); // Clear any previous message

//     try {
//       const response = await api.post('http://localhost:4000/api/participants/verify', { email ,quizId:token}     );
// //  console.log(response.data)
//       const data = response.data

//       if (data?.canPlay===false) {
//         setMessage("You've already completed the Quiz.");
//         shouldplay(false)

//       }

//       if (data?.canPlay===true) {
//           shouldplay(true)
//         setPemail(email)
//         setname(name)
//         setMessage('You can play the quiz.');
//         setcanPlay(true);
      

//       }
//  // Notify parent about verification
//       // Auto-close modal after 2 seconds
//     } catch (error) {
//         console.log(error)
//       setMessage('Failed to verify or register email.');
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center p-4 backdrop-blur-xl">
//       <div className="bg-[royalblue]/60 rounded-lg p-6 w-full max-w-md">
//         <h2 className="text-2xl font-bold mb-4 text-white/80">
//           Enter your email to start the quiz
//         </h2>
//         <form onSubmit={handleSubmit} className="space-y-4">
//           <input
//             type="email"
//             placeholder="Enter your email"
//             value={email}
//             onChange={(e) => setPemail(e.target.value)}
//             required
//             className="w-full p-2 border border-gray-300 rounded"
//           />
//               <input
//             type="name"
//             placeholder="Enter your name"
//             value={name}
//             onChange={(e) => setpname(e.target.value)}
//             required
//             className="w-full p-2 border border-gray-300 rounded"
//           />
//     <div className='flex gap-x-2 flex-wrap'>
//     <button
//             type="submit"
//             className={`flex-1 p-2 text-white rounded font-bold ${
//               isLoading ? 'bg-gray-400' : 'bg-green-600 hover:bg-green-400'
//             }`}
//             disabled={isLoading}
//           >
//             {isLoading ? 'Verifying...' : 'Continue'}
//           </button>
//  {  canPlay &&  <button
//             type="submit"
//             className={`flex-1 p-2 text-green-400 rounded font-bold bg-white`}
//           onClick={()=>{setIsOpen(false)}}
//           >
//            Play Quiz
//                      </button>}

//     </div>
        
//         </form>
//         {message && (
//           <p className="mt-4 text-center text-white/80 bg-green-700 p-2 rounded">
//             {message}
//           </p>
//         )}
//       </div>
//     </div>
//   );
// }



import React, { useEffect, useState } from 'react';
import api from '../../config/axious';
import { 
  Send, 
  CheckCircle, 
  XCircle, 
  Play, 
  Sparkles 
} from 'lucide-react';

export function EmailModal({ token, setemail, setname, shouldplay }) {
  const [email, setPemail] = useState('');
  const [name, setpname] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = useState(null);
  const [canPlay, setcanPlay] = useState(false);

  useEffect(() => {
    setemail(email)
    setname(name)
  }, [email, name]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage(null);

    try {
      const response = await api.post('/api/participants/verify', { email, quizId: token });
      const data = response.data

      if (data?.canPlay === false) {
        setMessage("You've already completed the Quiz.");
        shouldplay(false)
      }

      if (data?.canPlay === true) {
        shouldplay(true)
        setPemail(email)
        setname(name)
        setMessage('You can play the quiz.');
        setcanPlay(true);
      }
    } catch (error) {
      console.log(error)
      setMessage('Failed to verify or register email.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gradient-to-br from-pink-100 to-purple-200 p-4 backdrop-blur-md">
      <div className="relative w-full max-w-md bg-white rounded-3xl shadow-2xl border-4 border-pink-200 overflow-hidden">
        {/* Decorative Sparkle Background */}
        <div className="absolute top-0 left-0 w-full h-2 bg-gradient-to-r from-pink-400 to-purple-500" />
        
        <div className="p-8 space-y-6">
          <div className="flex items-center justify-center mb-6">
            <Sparkles className="w-12 h-12 text-pink-500 mr-4 animate-pulse" />
            <h2 className="text-3xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-purple-600">
              Quiz Adventure
            </h2>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="relative">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setPemail(e.target.value)}
                required
                className="w-full p-3 pl-10 border-2 border-pink-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-pink-400 transition-all duration-300"
              />
              <Send className="absolute left-3 top-1/2 -translate-y-1/2 text-pink-400" />
            </div>

            <div className="relative">
              <input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setpname(e.target.value)}
                required
                className="w-full p-3 pl-10 border-2 border-purple-200 rounded-xl focus:outline-none focus:ring-2 focus:ring-purple-400 transition-all duration-300"
              />
              <Play className="absolute left-3 top-1/2 -translate-y-1/2 text-purple-400" />
            </div>

            <div className="flex space-x-4">
              <button
                type="submit"
                disabled={isLoading}
                className={`flex-1 flex items-center justify-center p-3 rounded-xl text-white font-bold transition-all duration-300 ${
                  isLoading 
                    ? 'bg-gray-400 cursor-not-allowed' 
                    : 'bg-gradient-to-r from-pink-500 to-purple-600 hover:from-pink-600 hover:to-purple-700 hover:scale-105'
                }`}
              >
                {isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Verifying...
                  </>
                ) : (
                  <>
                    <CheckCircle className="mr-2" /> Continue
                  </>
                )}
              </button>

              {canPlay && (
                <button
                  type="button"
                  onClick={() => setIsOpen(false)}
                  className="flex-1 flex items-center justify-center p-3 bg-white text-pink-600 border-2 border-pink-300 rounded-xl font-bold hover:bg-pink-50 transition-all duration-300 hover:scale-105"
                >
                  <Play className="mr-2" /> Play Quiz
                </button>
              )}
            </div>
          </form>

          {message && (
            <div className={`mt-4 p-3 rounded-xl text-center flex items-center justify-center space-x-2 ${
              message.includes('already') 
                ? 'bg-red-100 text-red-600' 
                : 'bg-green-100 text-green-600'
            }`}>
              {message.includes('already') ? (
                <XCircle className="w-6 h-6" />
              ) : (
                <CheckCircle className="w-6 h-6" />
              )}
              <span>{message}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}