import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import CreateQuiz from './pages/CreateQuiz';
import EditQuiz from './pages/EditQuiz';
import QuizShareModal from './components/quiz/QuizShareModal';
import QuizPreview from './pages/QuizPreview';
import QuizParticipationPage from './pages/QuizParticipationPage'; 
import Results from './pages/Results';
import QuizAnalyticsDashboard from './pages/QuizAnalyticsDashboard';
import AppLayout from './components/layout/AppLayout';
import Auth from './components/common/Auth';

// PrivateRoute component for authentication protection
const PrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user'));
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    // Simulate checking auth status
    const checkAuth = async () => {
      try {
        setLoading(false);
      } catch (error) {
        console.error('Auth check failed:', error);
        setLoading(false);
      }
    };
    checkAuth();
  }, []);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600"></div>
      </div>
    );
  }

  return user ? children : <Navigate to="/" />;
};

// Main Routes component
const AppRoutes = () => {
  const user = JSON.parse(localStorage.getItem('user'));

  return (
    <Routes>
      {/* Public Route */}
      <Route
        path="/"
        element={user ? <Navigate to="/dashboard" /> : <Auth />}
      />
      <Route path="/quiz/:shareToken" element={<QuizParticipationPage />} />

      {/* Private Routes with AppLayout */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <AppLayout>
              <Dashboard />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route 
        path="/quiz/:shareToken" 
        element={<QuizShareModal />} 
      />
      <Route
        path="/createquiz"
        element={
          <PrivateRoute>
            <AppLayout>
              <CreateQuiz />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/edit-quiz/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <EditQuiz />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/quiz_preview/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <QuizPreview />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/results/:id"
        element={
          <PrivateRoute>
            <AppLayout>
              <Results />
            </AppLayout>
          </PrivateRoute>
        }
      />
      <Route
        path="/analytics"
        element={
          <PrivateRoute>
            <AppLayout>
              <QuizAnalyticsDashboard />
            </AppLayout>
          </PrivateRoute>
        }
      />

      {/* Fallback Route */}
      <Route
        path="*"
        element={user ? <Navigate to="/dashboard" /> : <Navigate to="/" />}
      />
    </Routes>
  );
};

// Main App component
const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gray-50">
        <AppRoutes />
      </div>
    </Router>
  );
};

export default App;