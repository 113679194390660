import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft, Plus, Sparkles, Save, X } from 'lucide-react';
import QuestionCard from '../components/quiz/QuestionCard';
import Navbar from '../components/layout/Navbar';
import Sidebar from '../components/layout/Sidebar';
import QuizShareModal from '../components/quiz/QuizShareModal';
import { motion, AnimatePresence } from 'framer-motion';
import api from '../config/axious';

const CreateQuiz = ({refreshStats}) => {
  const navigate = useNavigate();
  const [quiz, setQuiz] = useState({
    title: '',
    description: '',
    timeLimit: 30,
    questions: []
  });
  const [shareLink, setShareLink] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const c_id = JSON.parse(localStorage.getItem("user"));
    const u_id = c_id.id;

    try {
      const formData = new FormData();

      formData.append("title", quiz.title);
      formData.append("description", quiz.description);
      formData.append("timeLimit", quiz.timeLimit);
      formData.append("userid", u_id);

      const questionsWithMediaInfo = quiz.questions.map((q) => ({
        text: q.text,
        type: q.type,
        options: q.options,
        correctAnswer: q.correctAnswer,
        points: q.points,
        media: q.mediaFile ? q.mediaFile.name : null,
      }));
      formData.append("questions", JSON.stringify(questionsWithMediaInfo));

      quiz.questions.forEach((question) => {
        if (question.mediaFile) {
          formData.append("media", question.mediaFile);
        }
      });

      const response = await api.post('/api/quizzes', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      const data = await response.data;

      if (data.success) {
        setShareLink(data.shareLink);
      } else {
        alert("Failed to create quiz: " + data.message);
      }
    } catch (error) {
      console.error("Error creating quiz:", error);
      alert("Failed to create quiz. Please try again.");
    } finally {
      setIsSubmitting(false);
      // refreshStats()
    }
  };

  const addQuestion = () => {
    setQuiz(prev => ({
      ...prev,
      questions: [
        ...prev.questions,
        {
          text: '',
          type: 'multiple_choice',
          options: ['', ''],
          correctAnswer: '',
          points: 1,
          media: null
        }
      ]
    }));
  };

  return (
    <>
          <div className="max-w-4xl mx-auto">
            <motion.button
              onClick={() => navigate(-1)}
              className="flex items-center gap-2 text-indigo-600 mb-6 hover:text-indigo-800 transition-colors duration-200"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <ArrowLeft className="h-5 w-5" />
              Back to Dashboard
            </motion.button>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="bg-white rounded-xl shadow-lg p-8 border border-indigo-100"
            >
              <h1 className="text-3xl font-bold text-indigo-900 mb-6 flex items-center">
                <Sparkles className="h-8 w-8 text-indigo-500 mr-2" />
                Create Your Magical Quiz
              </h1>

              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-6">
                  <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
                    <label className="block text-sm font-medium text-indigo-700 mb-2">
                      Quiz Title
                    </label>
                    <input
                      type="text"
                      value={quiz.title}
                      onChange={(e) => setQuiz({ ...quiz, title: e.target.value })}
                      className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                      placeholder="Enter an enchanting quiz title"
                      required
                    />
                  </motion.div>

                  <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
                    <label className="block text-sm font-medium text-indigo-700 mb-2">
                      Description
                    </label>
                    <textarea
                      value={quiz.description}
                      onChange={(e) => setQuiz({ ...quiz, description: e.target.value })}
                      className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                      rows={3}
                      placeholder="Describe your magical quiz"
                      required
                    />
                  </motion.div>

                  <motion.div whileHover={{ scale: 1.02 }} transition={{ type: "spring", stiffness: 300 }}>
                    <label className="block text-sm font-medium text-indigo-700 mb-2">
                      Time Limit (minutes)
                    </label>
                    <input
                      type="number"
                      value={quiz.timeLimit}
                      onChange={(e) => setQuiz({ ...quiz, timeLimit: parseInt(e.target.value) })}
                      className="w-full px-4 py-2 border border-indigo-300 rounded-lg focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-all duration-200"
                      min="1"
                      required
                    />
                  </motion.div>

                  <AnimatePresence>
                    {quiz.questions.map((question, index) => (
                      <motion.div
                        key={index}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: -20 }}
                        transition={{ duration: 0.3 }}
                      >
                        <QuestionCard
                          question={question}
                          index={index}
                          onChange={(updatedQuestion) => {
                            const newQuestions = [...quiz.questions];
                            newQuestions[index] = updatedQuestion;
                            setQuiz({ ...quiz, questions: newQuestions });
                          }}
                          onDelete={() => {
                            const newQuestions = quiz.questions.filter((_, i) => i !== index);
                            setQuiz({ ...quiz, questions: newQuestions });
                          }}
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>

                  <motion.button
                    type="button"
                    onClick={addQuestion}
                    className="w-full py-4 border-2 border-dashed border-indigo-300 rounded-lg text-indigo-600 hover:border-indigo-500 hover:text-indigo-700 hover:bg-indigo-50 transition-all duration-200 flex items-center justify-center gap-2"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Plus className="h-5 w-5" />
                    Add Magical Question
                  </motion.button>

                  <div className="flex justify-between gap-4 pt-6">
                    <motion.button
                      type="button"
                      onClick={() => navigate('/dashboard')}
                      className="px-2 py-2 border border-red-200 rounded-lg text-red-700 hover:bg-red-100 transition-all duration-200"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                    >
                      <X className="h-5 w-5 inline mr-2" />
                      Cancel
                    </motion.button>
                    <motion.button
                      type="submit"
                      className="px-2 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-all duration-200 flex items-center"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      disabled={isSubmitting}
                    >
                      <Save className="h-5 w-5 mr-2" />
                      {isSubmitting ? 'Creating...' : 'Create Quiz'}
                    </motion.button>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        
    
      
      <AnimatePresence>
        {shareLink && (
          <QuizShareModal 
            shareLink={shareLink} 
            onClose={() => {
              setShareLink(null);
              navigate('/dashboard');
            }} 
          />
        )}
      </AnimatePresence>
    
    </>
    
  );
};

export default CreateQuiz;

